import * as React from 'react';
import styled from 'styled-components';

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 600px;
`;

const StyledLabel = styled.label`
  font-size: 24px;
  padding: 10px;
`;

const StyledInput = styled.input`
  font-size: 24px;
  padding: 10px;
  width: 100%;
`;

const StyledTextarea = styled(StyledInput)`
  height: 300px;
`;

const StyledButton = styled.button`
  padding: 10px;
  border-radius: 5px;
  border: 3px solid rgb(104, 237, 104);
  background-color: white;
`;

export default function ContactForm() {
  return (
    <StyledForm name="contact" netlify>
      <StyledLabel>
        Name <StyledInput type="text" name="name" />
      </StyledLabel>
      <StyledLabel>
        Email <StyledInput type="email" name="email" />
      </StyledLabel>
      <StyledLabel style={{ display: 'flex', flexDirection: 'column' }}>
        What can we do for you?{' '}
        <StyledTextarea type="textarea" name="comment" />
      </StyledLabel>
      <StyledButton type="submit">Send</StyledButton>
    </StyledForm>
  );
}
